const AboutUs: React.FC = () => {
    return <>
        <div className="container">
            <div className="row pb-0">
            <div className="col-12 p-5 custom-text-color d-flex" style={{ fontSize: "28px", fontWeight: "bold", justifyContent: "center", fontStyle: "italic" }}> About us </div>
                <div className="col-12 col-md-12 row pt-5 about-us">
                    <div className="col-12 col-md-6 " >
                        <div className="responsive-div text-dark">
                            <img src="About-us.avif" alt="Example Image" className="img-fluid" style={{ height: "inherit" }} />
                        </div>
                    </div>
                    <div className="col-12 col-md-6 main-container pt-4">
                        <div className="responsive-div banner p-4" style={{textAlign:"justify", color:"#1f2e59"}}>
                            <h2>About NeoFleet Realcon</h2>
                            <div className='pt-3'></div>                            
                            Welcome to NeoFleet Realcon - your trusted partner in property management. We understand the challenges that come with managing a property, which is why we are dedicated to providing services that simplify and enhance your experience. With a focus on transparency, affordability, and reliability, NeoFleet Realcon offers comprehensive solutions that maximize your investment returns and keep your property in prime condition.
                            Our team boasts extensive experience in the hospitality sector, ensuring that we deliver top-notch service and care to meet all your property management needs. We provide a wide range of services, including tenant search and inspections, maintenance, renovations, and society coordination.
                            Whether you're a property owner or investor, we are here to make property management hassle-free and rewarding.
                            With NeoFleet Realcon, your property is in good hands.Experience seamless property management with NeoFleet Realcon.

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default AboutUs