import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faEnvelopeOpen, faPhone } from '@fortawesome/free-solid-svg-icons';
import { mail, Address, ph_no } from "../../constant";

const ContactUs: React.FC = () => {

    const openMailto = () => {
        const mailtoUrl = `mailto:${mail.email}?subject=${encodeURIComponent(mail.subject)}&body=${encodeURIComponent(mail.body)}`;
        // window.location.href = mailtoUrl;
        window.open(mailtoUrl, '_blank');
    };
    const openDialPad = (phno: any) => {

        const telUrl = `tel:${phno}`;
        window.location.href = telUrl;
    };
    const openMap = () => {
        window.open(Address.map_url, '_blank');
    };
    return <>
        <div className="container pt-3">
            <div className="row pb-0">
                <div className="col-12 p-5 custom-text-color d-flex" style={{ fontSize: "28px", fontWeight: "bold", justifyContent: "center", fontStyle: "italic" }}> Contact us </div>
                <div className="col-12 col-md-12 row pt-2 about-us">
                    <div className="col-12 col-md-6 ">
                        <div className="responsive-div text-dark">
                            <img src="Contact-us.jpg" alt="Example Image" className="img-fluid" style={{ height: "inherit" }} />
                        </div>
                    </div>
                    <div className="col-12 col-md-6  pt-3">
                        <div className="responsive-div banner p-4">
                            <p style={{ fontSize: "28px", color: "#1f2e59" }}>Thank You for Your Interest in NeoFleet Realcon!</p>
                            <div className='pt-3'></div>
                            <p>For more details or any inquiries, feel free to reach out to us via call or WhatsApp.</p>
                            <div className="row" style={{ justifyContent: "space-evenly" }}>
                                <div style={{ width: "20%", textAlign: "center" }}>
                                    <FontAwesomeIcon icon={faPhone} color="#1f2e59" size="2x" />
                                </div>
                                <div style={{ display: "flex", alignItems: "center", width: '70%' }}>
                                    <p style={{ fontSize: "20px", color: "#1f2e59", textAlign: "left", cursor: "pointer", textDecoration: "underline" }} onClick={() => { openDialPad(ph_no.number_1) }}>{ph_no.number_1}</p>
                                    <p>, </p>
                                    <p style={{ fontSize: "20px", color: "#1f2e59", textAlign: "left", cursor: "pointer", textDecoration: "underline" }} onClick={() => { openDialPad(ph_no.number_2) }}>{ph_no.number_2}</p>
                                </div>
                            </div>
                            <div className="row" style={{ justifyContent: "space-evenly" }}>
                                <div style={{ width: "20%", textAlign: "center" }}>
                                    <FontAwesomeIcon icon={faEnvelopeOpen} color="#1f2e59" size="2x" />
                                </div>
                                <div style={{ display: "flex", alignItems: "center", width: "70%" }}>
                                    <p style={{ fontSize: "20px", color: "#1f2e59", textAlign: "left", cursor: "pointer", textDecoration: "underline" }} onClick={openMailto}>{mail.email}</p>
                                </div>
                            </div>
                            <div className="row" style={{ justifyContent: "space-evenly" }}>
                                <div style={{ width: "20%", textAlign: "center" }}>
                                    <FontAwesomeIcon icon={faLocationDot} color="#1f2e59" size="2x" />
                                </div>
                                <div style={{ display: "flex", alignItems: "center", width: "70%" }}>
                                    <p style={{ fontSize: "20px", color: "#1f2e59", cursor: "pointer", textDecoration: "underline" }} onClick={openMap}>{Address.Address_text}</p>
                                </div>
                            </div>
                            <div className="row pt-5" style={{ justifyContent: "space-evenly" }}>
                                We look forward to assisting you with all your property management needs. Your satisfaction is our priority!
                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default ContactUs