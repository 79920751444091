import { createBrowserRouter, RouterProvider } from "react-router-dom"
import "./pages/Privacy-policy/Privacy";
import Privacy from "./pages/Privacy-policy/Privacy";
import HomePage from "./pages/home/Homepage";

const App: React.FC = () => {

   const router = createBrowserRouter([
        {path:'/',element: <HomePage/>},
        {path:"privacy-policy", element: <Privacy/>}
    ])
    return <RouterProvider router={router} />
}
export default App