export const whatsapp_url = 'https://api.whatsapp.com/send?phone=917303071738&text=Thanks%20for%20Visiting%20our%20website!!!%20Please%20share%20your%20Query'

export const mail = {
    email: "info@neofleetreal.com", // Recipient's email address
    subject: "Hello, I have a question", // Subject of the email
    body: "I would like to know more about your services.", // Body of the email
};
export const ph_no = {
    number_1 :"9452441498",
    number_2 : "7489009145"
}
export const Address =  {
    map_url :"https://maps.app.goo.gl/SSNvARXkMeCv4nfk8",
    Address_text : "Office Address: 1526 Gaur City Mall Noida Extention"
}