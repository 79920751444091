import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional Theme applied to the Data Grid
import './Service.scss'
const Services: React.FC = () => {
    const arr = Array.from({ length: 16 });
    const painting_charges = [
        { Type: "OBD-Asian", onebhk: 7500, Twobhk: 12000, Threebhk: 17000 },
        { Type: "Tractor Emulson-Asian", onebhk: 16000, Twobhk: 19000, Threebhk: 24000 },
        { Type: "Premium Emulson-Asian", onebhk: 28667, Twobhk: 32000, Threebhk: 42000 },
        { Type: "Royal Emulson-Asian", onebhk: 39000, Twobhk: 55000, Threebhk: 70000 },
    ];
    const columnDefs: any[] = [
        { field: 'Type', headerName: "Type", maxWidth: 500, cellClass: 'custom-text-color' },
        { field: 'onebhk', headerName: "1 BHK", maxWidth: 100, cellClass: 'custom-text-color' },
        { field: 'Twobhk', headerName: "2 BHK", maxWidth: 100, cellClass: 'custom-text-color' },
        { field: 'Threebhk', headerName: "3 BHK", maxWidth: 100, cellClass: 'custom-text-color' }
    ]
    return <>
        <div className="container">
            {/* <div className='service-line-break'></div> */}
            <div className="col-12 p-5 custom-text-color d-flex" style={{ fontSize: "28px", fontWeight: "bold", justifyContent: "center", fontStyle: "italic" }}> Services </div>
            <div className="card-container d-flex align-items-center justify-content-center flex-wrap" style={{ gap: "2rem" }}>
                <div>
                    <div className='card-header'>
                        <h3 style={{ fontSize: "20px" }}>Consultation</h3>
                    </div>
                    <div className="card-box">
                        <div className="card-body">
                            <div className="card-imgContainer">
                                <img src="services/consultation.jpeg" alt="" />
                            </div>
                            <div className="content d-flex flex-column align-items-center justify-content-center">
                                <div>
                                    <p className="fs-6 text-white">We offer unlimited consultations to our customers, guiding them toward the best and most optimized decisions for starting their projects. Our expert team is dedicated to providing personalized advice and support, ensuring that you make informed choices that lead to successful outcomes.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='card-header'>
                        <h3 style={{ fontSize: "20px" }}>Renovation & Interior Work</h3>
                    </div>
                    <div className="card-box">
                        <div className="card-body">
                            <div className="card-imgContainer">
                                <img src="services/interior-design.jpg" alt="" />
                            </div>
                            <div className="content d-flex flex-column align-items-center justify-content-center">
                                <div>
                                    <p className="fs-6 text-white">Transform your property with our budget-friendly renovation options, designed to increase both value and appeal. At NeoFleet Realcon, we offer customized solutions to fit any budget, ensuring you get the most out of your investment. Explore our cost-effective renovation services today and see how we can help you achieve your property goals.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='card-header'>
                        <h3 style={{ fontSize: "20px" }}>Buy/Sell Assistance</h3>
                    </div>
                    <div className="card-box">
                        <div className="card-body">
                            <div className="card-imgContainer">
                                <img src="services/buy_sell_assisatance.jpg" alt="" />
                            </div>
                            <div className="content d-flex flex-column align-items-center justify-content-center">
                                <div>
                                    <p className="fs-6 text-white">Enjoy seamless buy/sell assistance with expert support at just 0.5%, ensuring smooth transactions. We take care of all society-related tasks for efficient coordination. Our complete furnishing solutions prepare your property for immediate occupancy. Plus, our professional packing and moving services ensure a stress-free relocation, handling every detail with care and precision.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='card-header'>
                        <h3 style={{ fontSize: "20px" }}>Service Agreement Renewal</h3>
                    </div>
                    <div className="card-box">
                        <div className="card-body">
                            <div className="card-imgContainer">
                                <img src="services/renewal.jpg" alt="" />
                            </div>
                            <div className="content d-flex flex-column align-items-center justify-content-center">
                                <div>
                                    <p className="fs-6 text-white">Renewing your service agreement with NeoFleet Realcon is simple and cost-effective. Our renewal fee is just 7 days' rent, ensuring uninterrupted access to our premium property management services. Enjoy seamless management with our easy, economical renewal process, keeping your property in top condition with expert care from NeoFleet Realcon.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='card-header'>
                        <h3 style={{ fontSize: "20px" }}>Professional House Painting</h3>
                    </div>
                    <div className="card-box">
                        <div className="card-body">
                            <div className="card-imgContainer">
                                <img src="services/house_painting.jpg" alt="" />
                            </div>
                            <div className="content d-flex flex-column align-items-center justify-content-center">
                                <div>
                                    <p className="fs-6 text-white">Enhance your property's look with our affordable professional repainting services. Our skilled team provides a high-quality finish that revitalizes your space, making it fresh and inviting. At NeoFleet Realcon, we offer top-notch repainting at competitive prices, ensuring your property stays appealing and valuable.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className='card-header'>
                        <h3 style={{ fontSize: "20px" }}>Rent In/Out</h3>
                    </div>
                    <div className="card-box">
                        <div className="card-body">
                            <div className="card-imgContainer">
                                <img src="services/renting_service.jpeg" alt="" />
                            </div>
                            <div className="content d-flex flex-column align-items-center justify-content-center">
                                <div>
                                    <p className="fs-6 text-white">Hassle-free property management with NeoFleet Realcon. For just 25 days' rent, get 11 months of comprehensive services, with no extra charges for tenant changes. We manage everything from maintenance to tenant relations, including rent agreements and police verification. Trust us to expertly care for your property at an affordable rate. Choose NeoFleet Realcon for peace of mind.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="container">
            <div className="row pb-0">
                <div className="col-12 col-md-12 row pt-4 pb-4">
                    <div className="col-12 p-5 custom-text-color d-flex" style={{ fontSize: "28px", fontWeight: "bold", justifyContent: "center", fontStyle: "italic" }}> Painting charges </div>
                    <div className="col-lg-6 col-sm-12  pt-3 ag-theme-alpine justify-content-center" style={{ height: 270, margin: "auto", paddingBottom: "20px" }}>
                        <AgGridReact
                            columnDefs={columnDefs}
                            defaultColDef={{ sortable: false, resizable: false }}
                            rowData={painting_charges}
                            suppressMovableColumns={true}
                        />
                    </div>

                </div>
                <div className='service-line-break'></div>
            </div>
        </div>

        <div>
            <div className="col-12 p-3 custom-text-color d-flex" style={{ fontSize: "28px", fontWeight: "bold", justifyContent: "center", fontStyle: "italic" }}> Gallery </div>
            <Carousel
                autoPlay={true}
                infiniteLoop={true}
                interval={3000}
                dynamicHeight={false}
                showThumbs={false}
                stopOnHover={false}
                showArrows={false}
                showStatus={false}
            >
                {arr.map((e, i) => {
                    return (
                        <div>
                            <img src={`carousel/img_${i + 1}.png`} alt="carousel image" style={{ maxHeight: "650px" }} />
                        </div>
                    );
                })}
            </Carousel>
        </div>
    </>
}
export default Services