import { Modal } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Privacy: React.FC = () => {
    const navigate = useNavigate();
    const [popup, setpopup] = useState<boolean>(false);
    useEffect(() => {
        setpopup(true)
    }, [])

    const goToHomePage = () => {
        setpopup(false);
        navigate('/');
    }
    return <>
        <Modal size="lg" show={popup} onHide={goToHomePage} aria-labelledby="privacy policy">
            <Modal.Header closeButton={true} closeLabel='black'>
                <Modal.Title id="privacy-policy">Privacy Policy</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Welcome to NeoFleetReal.com.<br />
                This privacy policy outlines how we collect, use, and protect your personal information when you interact with our website and services, particularly our WhatsApp marketing activities.<br />
                <h5>1. Information We Collect</h5><br />
                We may collect and process the following types of information:<br />
                a. Personal Information<br />
                Contact Information: Your name, email address, and phone number.<br />
                Communication Data: Information from your communication with us via WhatsApp, including messages, feedback, and inquiries.<br />
                b. Non-Personal Information<br />
                Technical Data: IP address, browser type, operating system, and access times.<br />
                Usage Data: Information about how you use our website, pages visited, and duration of visits.<br />
                <h5>2. How We Use Your Information</h5><br />
                We use the collected information for the following purposes:<br />
                WhatsApp Marketing: To send you promotional messages, updates, and offers through WhatsApp.<br />
                Improving Our Services: To enhance the user experience on our website by analyzing usage patterns and technical data.<br />
                Communication: To respond to your inquiries, requests, and provide customer support.<br />
                <h5>3. Data Sharing and Disclosure</h5><br />
                We do not sell, trade, or otherwise transfer your personal information to outside parties, except as described below:<br />
                Service Providers: We may share your information with trusted third-party service providers who assist us in operating our website and conducting our business. These parties are obligated to maintain the confidentiality and security of your information.<br />
                Legal Requirements: We may disclose your information when required by law or to protect our rights, property, or safety.<br />
                <h5>4. Data Security</h5><br />
                We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, use, or disclosure. However, no internet or email transmission is ever fully secure or error-free.<br />
                <h5>5. Your Rights</h5><br />
                You have the following rights regarding your personal information:<br />
                Access: You can request access to the personal information we hold about you.<br />
                Correction: You can request that we correct any inaccuracies in your personal information.<br />
                Deletion: You can request the deletion of your personal information, subject to certain legal obligations.<br />
                <h5>6. Changes to This Privacy Policy</h5><br />
                We may update this privacy policy from time to time to reflect changes in our practices or legal requirements. We will notify you of any significant changes by posting the updated policy on our website.<br />
                <h5>7. Contact Us</h5><br />
                If you have any questions or concerns about this privacy policy or our data practices, please contact us at:<br />
                Email: info@neofleetreal.com<br />
                Phone: +91-9452441498, +91-7489009145<br />
                Thank you for trusting NeoFleetReal.com. We are committed to protecting your privacy and ensuring your data is secure.<br />
            </Modal.Body>
        </Modal>
    </>
}
export default Privacy;