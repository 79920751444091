import './Homepage.scss'
import logo from '../../Images/logo.png'
import Services from '../Services/Services'
import { useState, useRef, useEffect } from 'react'
import AboutUs from '../AboutUs/AboutUs'
import ContactUs from '../ContactUs/ContactUs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSquareWhatsapp } from '@fortawesome/free-brands-svg-icons'
import { whatsapp_url } from '../../constant'
import { Carousel } from 'react-responsive-carousel'
import { Link } from 'react-router-dom'
const HomePage: React.FC = () => {
    const arr = Array.from({ length: 2 });
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 992);
    const sectionRefs = useRef<{ [key: string]: HTMLDivElement | null }>({
        section1: null,
        section2: null,
        section3: null,
    });

    const scrollToSection = (section: string) => {
        if (sectionRefs.current[section]) {
            const target = sectionRefs.current[section];
            window.scrollTo({ top: target!.offsetTop - 70, behavior: "smooth" })
            if (isMobileView) {
                var button = document.getElementById('navbarbutton') as HTMLElement
                button.click()
            }
        }
    };

    useEffect(() => {
        const handleResize = () => setIsMobileView(window.innerWidth < 992);
        window.addEventListener('resize', handleResize);
        //scrollToSection('home')
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const openWhatsApp = () => {
        window.open(whatsapp_url, '_blank');
    };
    return <>
        <div className='main-container'>
            <div className="col-12 col-md-12 navbar-sticky">
                <nav className="navbar navbar-expand-lg">
                    <p
                        className="navbar-brand"
                        style={{
                            color: '#1f2e59',
                            fontWeight: "bold",
                            cursor: "pointer",
                            margin: 0, // Remove default margin
                            display: 'flex', // Use flex for vertical alignment
                            flexDirection: 'column', // Stack the brand name and subtext vertically
                        }}
                        onClick={() => { scrollToSection('home') }}
                    >
                        NEO FLEET REALCON
                        <sub style={{ fontSize: '8px', fontWeight: 'normal', paddingLeft: "10px" }}>
                            Property Management - A way to manage your property
                        </sub>
                    </p>
                    <button id='navbarbutton' className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
                        <ul className="navbar-nav ms-auto">
                            <li className="nav-item">
                                <p className="nav-link" style={{ color: '#1f2e59', fontWeight: "bold", cursor: "pointer", margin: 0 }} onClick={() => { scrollToSection('services') }}>Services</p>
                            </li>
                            <li className="nav-item">
                                <p className="nav-link" style={{ color: '#1f2e59', fontWeight: "bold", cursor: "pointer", margin: 0 }} onClick={() => { scrollToSection('about') }}>About</p>
                            </li>
                            <li className="nav-item">
                                <p className="nav-link" style={{ color: '#1f2e59', fontWeight: "bold", cursor: "pointer", margin: 0 }} onClick={() => { scrollToSection('contact') }}>Contact us</p>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
            <div>
                <Carousel
                    autoPlay={true}
                    infiniteLoop={true}
                    interval={3000}
                    dynamicHeight={false}
                    showThumbs={false}
                    stopOnHover={false}
                    showArrows={false}
                    showStatus={false}
                >
                    {arr.map((e, i) => {
                        return (
                            <div>
                                <img src={`Home_slider_${i + 1}.jpg`} alt="carousel image" style={{ maxHeight: "600px" }} />
                            </div>
                        );
                    })}
                </Carousel>
            </div>

            <div className="container" ref={(el) => (sectionRefs.current['home'] = el)}>
                <div className="row pb-0">
                    <div className="col-12 col-md-12 row pt-3">
                        <div className="col-12 col-md-12 child-2-main-container pt-3">
                            <div className="banner text-white p-4">
                                <h2>NEO FLEET REALCON</h2>
                                <p>A way to manage your property.</p>
                                <div className='line-break'></div>
                                <p className='pt-5 content'>
                                    Are you tired of the hassle of managing your property?   <br />
                                    Look no further! NeoFleet Realcon offers a comprehensive range of property management services at the best prices in Noida and Delhi/NCR region, designed to make your life easier and maximize your investment returns.
                                </p>
                            </div>
                            <div className="banner text-white p-4">
                                <p style={{ textAlign: "left" }}>With NeoFleet Realcon, you gain access to:</p>
                                <ul>
                                    <li>24/7 Support: Our dedicated team is available around the clock to handle any property-related issues, ensuring peace of mind for property owners</li>
                                    <li>Expert Maintenance: We provide professional maintenance services to keep your property in pristine condition, enhancing its value and appeal.</li>
                                    <li>Tenant Relations: We take care of tenant screening, leasing, and management, ensuring your property is always occupied by responsible tenants who respect and care for your investment.</li>
                                    <li>Legal Compliance: We ensure comprehensive compliance with local laws and regulations, protecting you from potential legal issues and fines.</li>
                                    <li>Customized Solutions: Tailored property management plans to fit your unique needs, from single units to large portfolios, ensuring you receive the best service possible.</li>
                                </ul>
                                <p className='pt-5 content'>At NeoFleet Realcon, we understand the complexities and challenges of property management. Our goal is to provide solutions that simplify and enhance your ownership experience. Let us take the burden off your shoulders so you can enjoy the benefits of your investments without the stress.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div ref={(el) => (sectionRefs.current['services'] = el)}> <Services /> </div>
            {/* )}
            {activepage === 2 && ( */}
            <div ref={(el) => (sectionRefs.current['about'] = el)}> <AboutUs /> </div>
            {/* )}
            {activepage === 3 && ( */}
            <div ref={(el) => (sectionRefs.current['contact'] = el)}> <ContactUs /> </div>
            {/* )} */}
        </div>
        <div className='container pt-5'>
            <div className="row text-white p-3" style={{ color: "#1f2e59", justifyContent: "space-evenly", backgroundColor: "#1f2e59" }}>
                <div style={{ width: "50%" }}> &#xA9; Copyrights 2024-2027. NeoFleet Realcon. All Rights Reserved.</div>
                <div style={{ width: "50%", textAlign: "center" }}>
                    <Link to={'privacy-policy'} style={{ color: "#FFFFFF"}}>Privacy policy</Link>
                </div>

            </div>
        </div>
        <div style={{ position: "sticky", zIndex: 9999, textAlign: "right", bottom: 0 }}>
            <span style={{ cursor: "pointer" }}><FontAwesomeIcon icon={faSquareWhatsapp} style={{ color: "#00e646", }} size="3x" onClick={openWhatsApp} /></span>
        </div>
    </>
}
export default HomePage